<template>
  <svg
    version="1.1" id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1366 768" :style="'enable-background:new 0 0 1366 768;width:'+width"
     xml:space="preserve"
  >
    <g>
      <path :style="{fill: fill}" d="M711.5,502.6c-8,0-13.7-1.3-16.9-4c-3.2-2.6-4.9-7.6-4.9-14.9c0-2.9,0.5-7.2,1.5-12.8c1-5.7,2.1-10.7,3.4-15.1l15.5-54.8
        c1.5-5,2.6-10.6,3.1-16.6c0.6-6,0.8-10.2,0.8-12.6c0-11.6-4.1-20.9-12.2-28.2c-8.1-7.2-19.6-10.8-34.6-10.8
        c-8.3,0-17.1,1.5-26.4,4.4c-9.3,2.9-19.1,6.5-29.3,10.6l-4.2,17c3-1.1,6.7-2.3,10.9-3.6c4.2-1.2,8.3-1.9,12.4-1.9
        c8.2,0,13.7,1.4,16.6,4.1c2.9,2.8,4.4,7.7,4.4,14.7c0,3.9-0.5,8.2-1.4,12.9c-0.9,4.7-2.1,9.7-3.5,15l-15.6,55.1
        c-1.4,5.8-2.4,11-3,15.6c-0.6,4.6-0.9,9.1-0.9,13.5c0,11.3,4.2,20.6,12.5,28c8.4,7.3,20.1,11,35.2,11c9.8,0,18.4-1.3,25.8-3.9
        c7.4-2.6,17.3-6.3,29.8-11.2l4.1-17c-2.1,1-5.6,2.2-10.4,3.5C719.6,501.9,715.3,502.6,711.5,502.6z"
      />
      <path :style="{fill: fill}" d="M727.8,242.4c-7.2-6.8-15.9-10.2-26.1-10.2c-10.2,0-18.9,3.4-26.2,10.2c-7.3,6.8-10.9,15-10.9,24.5
        c0,9.6,3.7,17.7,10.9,24.4c7.3,6.7,16,10.1,26.2,10.1c10.2,0,18.9-3.4,26.1-10.1c7.2-6.7,10.8-14.9,10.8-24.4
        C738.6,257.3,735,249.2,727.8,242.4z"
      />
      <path :style="{fill: fill}" d="M683,149.5c-129.3,0-234.5,105.2-234.5,234.5S553.7,618.5,683,618.5S917.5,513.3,917.5,384S812.3,149.5,683,149.5z
         M683,585.9c-111.3,0-201.9-90.6-201.9-201.9c0-111.3,90.6-201.9,201.9-201.9c111.3,0,201.9,90.6,201.9,201.9
        C884.9,495.3,794.3,585.9,683,585.9z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: ['fill', 'width']
};
</script>
