<template>
  <!-- Modal reserva editada exitosamente -->
  <b-modal :active.sync="openModal" :can-cancel="false" id="updatedReservation">
    <div class="modal-body-success" :style="{backgroundColor: getBackOpa()}">
      <span class="success-icon">
        <i class="icon_round_check icon-mks"></i>
      </span>
      <div class="logoDiv"
        :class="{
          'logoBorder': (design.typeLogo == 'border'),
          'logoRibbon': (design.typeLogo == 'ribbon'),
          'padding': (design.typeLogo == 'basic2'),
        }"
      >
        <div class="centerlog" v-if="design.typeLogo == 'basic'">
          <img :src="vendor.lightLogoURL" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'ancho'">
          <img :src="vendor.lightLogoURL" class="mx-auto max-h200" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'basic2'">
          <img :src="vendor.lightLogoURL" class="basic2" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'basic3'">
          <img :src="vendor.lightLogoURL" class="basic3" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'border'">
          <img :src="vendor.lightLogoURL" class="mx-auto d-block img-border logo-border-img" />
        </div>
        <div class="centerlog" v-if="design.typeLogo == 'large'">
          <img :src="vendor.lightLogoURL" class="mx-auto d-block img-large" />
        </div>
        <div class="rabion" v-if="design.typeLogo == 'ribbon'">
          <svg height="160" width="100">
            <polygon
              class="bookmark"
              data-v-368b4645
              points="0,0 0,150 50,120 100,150 100,0"
            />
          </svg>
          <img :src="vendor.lightLogoURL " />
        </div>
      </div>
      <p class="title-modal mb-12px">{{ $t('newEditReservation.modifiedSuccess') }}</p>
      <p class="success-text mb-12px">{{ $t('newEditReservation.updatedData') }}:</p>
      <section class="updated-reservation-data" v-if="updatedReservation">
        <article class="updated-reservation-item">
          <i class="icon_user_outlined icon-mks"></i>
          <p class="text-modal">{{ updatedReservation.people }} pax</p>
        </article>
        <article class="updated-reservation-item">
          <i class="icon_calendar icon-mks"></i>
          <p class="text-modal">{{ parseInt(updatedReservation.date) | moment('timezone', vendorTimezone, 'DD/MMM/YY') }}</p>
        </article>
        <article class="updated-reservation-item">
          <i class="icon_hour_outlined icon-mks"></i>
          <p class="text-modal">{{ parseInt(updatedReservation.date) | moment('timezone', vendorTimezone, 'hh:mm a') }}</p>
        </article>
        <article class="updated-reservation-item" v-if="zoneName">
          <i class="icon_check_dotted icon-mks"></i>
          <p class="text-modal">{{ zoneName }}</p>
        </article>
        <article class="updated-reservation-item" v-if="updatedReservation.experienceName">
          <i class="icon_experience_outlined icon-mks"></i>
          <p class="text-modal">{{ $t('experience') }}</p>
        </article>
        <article class="updated-reservation-item" v-if="updatedReservation.decorationId">
          <i class="icon_decoration_outlined icon-mks"></i>
          <p class="text-modal">{{ $t('decoration') }}</p>
        </article>
        <article class="updated-reservation-item" v-if="updatedReservation.balancePaid > 0">
          <i class="icon_money_outlined icon-mks"></i>
          <p class="text-modal pre-compra">{{ $t('preCompra') }} <br>
            {{ (updatedReservation.balancePaid) | currency("$ ", 0, { thousandsSeparator: "," }) }}
          </p>
        </article>
      </section>
      <div class="btns-modal">
        <button class="btn-modal btn-modal-confirm" @click="closeModal">{{ $t('close') }}</button>
      </div>
      <p class="contact-text">{{ $t('informationReservationContact') }} <strong>{{ vendor.phone }}</strong></p>
      <div class="column modal-footer-logo">
        BUILD BY |
        <i class="icon-mks icon-logo-precompro"></i>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['updatedReservation', 'value'],
  data () {
    return {
      openModal: false
    };
  },
  computed: {
    zoneName () {
      if (this.zones) {
        return this.zones.find(z => z.id === parseInt(this.updatedReservation.zoneId)).displayName || null;
      } else {
        return null;
      }
    }
  },
  methods: {
    closeModal () {
      this.openModal = false;
      this.$emit('success-updated-close');
    },
    getBackOpa () {
      let colorObject = this.hexToRgb(this.design.bgColorCheckout);
      if (colorObject === null) {
        colorObject = this.hexToRgb(this.design.color1);
      }
      if (this.design.opacityInEdit) {
        return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.8)`;
      }
      return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b})`;
    }
  },
  watch: {
    value () {
      this.openModal = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
#updatedReservation{
  .title-modal {
    color: var(--colorCheckout) !important;
  }
  .text-modal {
    color: var(--bgColorCheckout) !important;
  }
  .btn-modal-confirm {
    background-color: var(--colorCheckout) !important;;
    color: var(--bgColorCheckout) !important;;
  }
  .modal-body-success{
    border-radius: 20px;
    margin: 0 auto;
    max-width: 600px;
    padding: 40px 60px;
    position: relative;

    .success-icon{
      align-items: center;
      background-color: #3daf41;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      height: 50px;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 0px;
      width: 50px;
      .icon-mks{
        background-color: #fff;
        height: 30px;
        margin: 0;
        width: 30px;
      }
    }

    .logoDiv{
      margin-bottom: 25px;
    }

    .updated-reservation-data{
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;

      .updated-reservation-item{
        align-items: center;
        background-color: var(--colorCheckout);
        border-radius: 8px;
        border: 1px solid var(--colorCheckout);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 95px;
        padding: 15px 5px;
        width: 95px;
        gap: 5px;

        .text-modal{
          word-wrap: break-word;
          max-width: 100%;
          &.pre-compra{
            line-height: 1;
          }
        }

        .icon-mks{
          background-color: var(--bgColorCheckout);
          width: 30px;
          height: 30px;
          margin: 0;
        }
      }
    }

    .success-text{
      font-size: 14px;
      color: var(--colorCheckout);
      text-align: center;
    }

    .contact-text{
      font-size: 11px;
      color: var(--colorCheckout);
      margin-top: 30px;
      text-align: center;
      strong{
        color: var(--colorCheckout);
        text-decoration: underline;
      }
    }
    .modal-footer-logo{
      align-items: center;
      color: #b3b3b3;
      display: flex;
      font-size: 13px;
      font-weight: 500;
      justify-content: center;
      margin-top: 30px;
      max-height: 25px;
      text-align: right;
      img{
        max-width: 100%;
        height: 15px;
        margin-top: 7px;
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 530px){
    .modal-body-success{
      padding: 35px;
      .logoDiv{
        margin-bottom: 10px;
      }
    }
  }
}
</style>
