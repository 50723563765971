<template>
  <div class="sucessfull-operation">
    <i class="sucessfull-operation__icon icon-mks icon_sucess_operation"></i>
    <h2 class="sucessfull-operation__title">Operación exitosa</h2>
    <p class="sucessfull-operation__text">Se canceló la reserva</p>
    <div class="sucessfull-operation__actions">
      <b-button @click="$parent.close()">Cerrar</b-button>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
  .modal-sucessfull-operation{
    .animation-content{
      max-width: 330px!important;
      width: 100%;
      padding: 20px;
      background: white;
      border-radius: 20px;
      text-align: center;
    }
    .sucessfull-operation{
      justify-items: center;
      display: grid;
      gap: 15px;
      line-height: normal;
      color: #000;
      &__icon{
        margin: 0;
      }
      &__title{
        font-size: 18px;
      }
      &__text{
        font-size: 16px;
      }
      &__actions{
        button{
          height: 40px!important;
          min-width: 160px;
          border-radius: 10px;
          font-size: 14px!important;
          border: 1px solid #000;
          font-family: "Source Sans Pro"!important;
        }
      }
    }
  }
</style>
